import React, { Component } from 'react'

import Logo from '../logo'

import './footer.css'
import Resume from '../../assets/Adam Passey Resume & Cover Letter.pdf'

class Footer extends Component {

	render() {
		return (
			<div className="footer">
				<div className="container">
					<Logo type="white" size="tiny" />
					<ul className="link-icons">
						<li>
							<a href="https://github.com/adampassey" title="Adam Passey on Github" target="_blank" rel="noreferrer">
								<i class="fab fa-github"></i>
							</a>
						</li>
						<li>
							<a href="https://www.linkedin.com/in/adam-passey-01633b38/" title="Adam Passey on LinkedIn" target="_blank" rel="noreferrer">
								<i class="fab fa-linkedin"></i>
							</a>
						</li>
						<li>
							<a href="mailto: adamjpassey@gmail.com" title="Adam Passey's email">
								<i className="material-icons small">email</i>
							</a>
						</li>
					</ul>
					<div className="phone-number right">
						<p><a href="tel:541-864-9427" className="phone-number" title="Adam Passey's phone number">541.864.9427</a></p>
					</div>
					<p>
						<span className="emphasized">
							Everything
						</span> you see here was solely designed and developed by &nbsp;
						<span className="emphasized emphasized--small emphasized--light emphasized--inline">
							Adam
						</span> 
						<span className="emphasized emphasized--small emphasized--dark emphasized--inline">
							Passey
						</span>.
						<br />
						<span className="built-by">Built with <a href="https://reactjs.org/" title="React" target="_blank" rel="noreferrer">React</a> and 
						hosted by <a href="https://pages.github.com/" title="Github Pages" target="_blank" rel="noreferrer">Github Pages</a>.</span>
					</p>
					<p className="left resume-link">
                        <a href={Resume} title="Download Adam Passey's Resume">
							<i className="material-icons small">description</i>
							Download my resume
						</a>
                    </p>
				</div>
			</div>
		)
	}
}

export default Footer