import React, { Component } from 'react'

import RotatingPicture from '../rotating-picture'

import './work-history.css'

import SingularityLogo from '../../assets/singularity-6-logo.png'
import BlizzardLogo from '../../assets/blizzard-logo-blue-transparent.png'
import IGNLogo from '../../assets/IGN-Logo.png'
import WhistleLogo from '../../assets/Whistle_Logo_Grey.png'

class WorkHistory extends Component {

	render() {
		return (
			<div className="work-history-container">
				<div className="container row">
					<RotatingPicture />
					<div className="blurb col s12 l7 pull-l4">
						<p className="flow-text">
							<strong>Current founder of stealth start-up in the gaming space.</strong>
						</p>
						<p className="flow-text">
							Former Tech Lead at <a href="https://www.singularity6.com/" title="Singularity 6" target="_blank">Singularity 6</a> working
							on <a href="https://palia.com/" title="Palia, a new community sim game for you and your friends" target="_blank">Palia</a>. Previously the
							the Director of Software Engineering at <a href="https://whistle.com" title="Whistle" target="_blank" rel="noreferrer">Whistle</a>,
							Technical Lead of the San Francisco Game Sites team
							at <a href="https://blizzard.com" title="Blizzard" target="_blank" rel="noreferrer">Blizzard</a>,
							engineering at <a href="https://ign.com" title="IGN Entertainment" target="_blank" rel="noreferrer">IGN Entertainment</a>,
							co-creator of <a href="https://ign.com/maps" title="IGN's Interactive Maps" target="_blank" rel="noreferrer">IGN's interactive maps platform</a>,
							and leader of the <a href="https://ign.com/code-foo/2020" title="IGN's Code-Foo" target="_blank" rel="noreferrer">Code-Foo program</a>.
						</p>
						<p className="flow-text">
							Originally the VP of Information &amp;
							Technology at <a href="https://passeyadvertising.com" title="Passey Advertising, Inc." target="_blank" rel="noreferrer">Passey Advertising Inc</a>.
						</p>
					</div>
				</div>
				<div className="container row">
					<div className="work-history-logos row">
						<a href="https://singularity6.com" title="Singularity 6" target="_blank" alt="Singularity 6" className="work-history-logo work-history-logo--singularity-6" rel="noreferrer">
							<img src={SingularityLogo} className="col s12 m6 l3" alt="Singularity 6" />
						</a>
						<a href="https://blizzard.com" title="Blizzard" target="_blank" alt="Blizzard" className="work-history-logo work-history-logo--blizzard" rel="noreferrer">
							<img src={BlizzardLogo} className="col s12 m6 l3" alt="Blizzard" />
						</a>
						<a href="https://ign.com" title="IGN" alt="IGN" target="_blank" className="work-history-logo work-history-logo--ign" rel="noreferrer">
							<img src={IGNLogo} className="col s12 m6 l3" alt="IGN" />
						</a>
						<a href="https://whistle.com" title="Whistle" alt="Whistle" target="_blank" className="work-history-logo work-history-logo--whistle" rel="noreferrer">
							<img src={WhistleLogo} className="col s12 m6 l3" alt="Whistle" />
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default WorkHistory