import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Project from '../project'

import NavigatorsSource from '../../assets/project-navigators-500.jpg'
import AffinitySource from '../../assets/project-affinity-500.jpg'
import HackWeekSource from '../../assets/hack-week-500.jpg'
import LudumDareSource from '../../assets/ludum-dare-40-500.jpg'

import './projects.css'

class Projects extends Component {

	render() {
		return (
			<div className="container--projects">
				<div className="container--projects-sub-container">
					<div className="container">
						<h4 className="projects-sub-head--blocky">{this.props.headline}</h4>
						<div className="row">
							<Project 
								image={NavigatorsSource} 
								title="Project: Navigators" 
								description="Solo Unity project where you manage the crew of a submarine trying to avoid the dangers of the deep. Inspired by FTL, RimWorld, and Overcooked."
								linkText="View Video"
								openLinkInModal={true}
								embedCode='<iframe width="100%" height="100%" src="https://www.youtube.com/embed/kAtQP-O_qCY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
								materializeSize="s12 m6 xl3"
							/>
							<Project 
								image={AffinitySource} 
								title="Project: Affinity"
								description="Solo Unity project where you create potions and tinctures using a myriad of ingredients and tools. Inspired by Papers, Please."
								linkText="View Video"
								openLinkInModal={true}
								embedCode='<iframe width="100%" height="100%" src="https://www.youtube.com/embed/NO4x8DSSTvU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
								materializeSize="s12 m6 xl3"
							/>
							<Project
								image={LudumDareSource}
								title="Ludum Dare 40"
								description="Entry into the Ludum Dare 40 game jam. Inspired by Katamari Damacy."
								linkText="View Video"
								openLinkInModal={true}
								embedCode='<iframe width="100%" height="100%" src="https://www.youtube.com/embed/_ZIxlrzZoi4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
								materializeSize="s12 m6 xl3"
							/>
							<Project
								image={HackWeekSource}
								title="Hack Week(s)"
								description="Entries to several different Hack Week competitions. Inspired by anything and everything."
								linkText="View Video"
								openLinkInModal={true}
								embedCode='<iframe width="100%" height="100%" src="https://www.youtube.com/embed/Vp120uNUCSg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
								materializeSize="s12 m6 xl3"
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

Projects.propTypes = {
	headline: PropTypes.string,
}

Projects.defaultProps = {
	headline: "Personal Projects",
}

export default Projects