import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './pixelart.css'
import mastheadSource from '../../assets/masthead.png'
import waterfallAnimation1 from '../../assets/waterfall-animation1.png'
import waterfallAnimation2 from '../../assets/waterfall-animation2.png'
import waterfallAnimation3 from '../../assets/waterfall-animation3.png'
import waterfallAnimation4 from '../../assets/waterfall-animation4.png'
import waterfallAnimation5 from '../../assets/waterfall-animation5.png'
import waterfallAnimation6 from '../../assets/waterfall-animation6.png'
import waterfallAnimation7 from '../../assets/waterfall-animation7.png'

class PixelArt extends Component {

	constructor(props) {
		super(props)

		this.state = {
			currentFrame: 0,
			waterfallFrame: waterfallAnimation1,
			ballState: 0,
			ballBackgroundPosition: 0,
		}

		this.waterfallAnimations = [
			waterfallAnimation1,
			waterfallAnimation2,
			waterfallAnimation3,
			waterfallAnimation4,
			waterfallAnimation5,
			waterfallAnimation6,
			waterfallAnimation7,
		]
	}

	componentDidMount() {
		setInterval(this.tick, this.props.animationSpeed)
		setInterval(this.ballTick, this.props.ballAnimationSpeed)
		setInterval(this.ballBackgroundTick, this.props.ballBackgroundAnimationSpeed)
	}

	tick = () => {
		const frame = this.state.currentFrame >= this.waterfallAnimations.length - 1 ? 0 : this.state.currentFrame + 1
		this.setState({
			currentFrame: frame,
			waterfallFrame: this.waterfallAnimations[frame],
		})
	}

	ballTick = () => {
		const newState = this.state.ballState === 0 ? 1 : 0
		this.setState({
			ballState: newState,
		})
	}

	ballBackgroundTick = () => {
		var newFrame = this.state.ballBackgroundPosition + 1
		if (newFrame > this.props.ballBackgroundFrames - 1) {
			newFrame = 0
		}

		this.setState({
			ballBackgroundPosition: newFrame,
		})
	}

	render() {
		const ballStateClass = this.state.ballState === 0 ? 'pixelart--floating-ball--top' : 'pixelart--floating-ball--bottom'
		const ballBackgroundFrame = this.state.ballBackgroundPosition

		return (
			<div className="container--pixelArt">
				<div className={`pixelart pixelart--floating-ball ${ballStateClass} position-${ballBackgroundFrame}`} />
				<img src={mastheadSource} className="pixelart pixelart--main" alt="" />
				<img src={this.state.waterfallFrame} className="pixelart pixelart--waterfall" alt="" />
			</div>
		)
	}
}

PixelArt.propTypes = {
	animationSpeed: PropTypes.number,
	ballAnimationSpeed: PropTypes.number,
	ballBackgroundAnimationSpeed: PropTypes.number,
	ballBackgroundFrames: PropTypes.number,
}

PixelArt.defaultProps = {
	animationSpeed: 100,
	ballAnimationSpeed: 1000,
	ballBackgroundAnimationSpeed: 40,
	ballBackgroundFrames: 13,
}

export default PixelArt