import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './logo.css'

class Logo extends Component {

	render() {
		const {
			type,
			size,
		} = this.props

		return (
			<div className={`logo logo-bar--${type} logo-bar--${size}`}>
				<div className="logo-bar logo-bar--long logo-bar--dark" />
				<div className="logo-bar logo-bar--short logo-bar--dark" />
				<div className="logo-bar logo-bar--long logo-bar--dark" />
				<div className="logo-bar logo-bar--long logo-bar--light" />
				<div className="logo-bar logo-bar--short logo-bar--light" />
			</div>
		)
	}
}

Logo.propTypes = {
	type: PropTypes.string,
	size: PropTypes.string,
}

Logo.defaultProps = {
	type: "black",
	size: "tall",
}

export default Logo