import React, { Component } from 'react'

import './fast-company.css'

import FastCompanyLogo from '../../assets/fast-company-logo.png'

class FastCompany extends Component {
	render() {
		return (
			<div className="fast-company-container">
				<div className="container">
					<div className="row">
						<a href="https://www.fastcompany.com/1784737/silicon-valleys-new-hiring-strategy" title="Fast Company: Silicon Valley's New Hiring Strategy">
							<img src={FastCompanyLogo} alt="Fast Company Logo" className="col s12 m6" />
						</a>
						<div className="col s12 m5 offset-m1">
							<p className="flow-text">
								See Adam Passey mentioned in Fast Company magazine 
								on <a href="https://www.fastcompany.com/1784737/silicon-valleys-new-hiring-strategy" title="Fast Company: Silicon Valley's New Hiring Strategy" target="_blank" rel="noreferrer">Silicon Valley's New Hiring Strategy &raquo;</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default FastCompany