import React, { Component } from 'react'

import RotatingPictureSlice from '../rotating-picture-slice'

import './rotating-picture.css'

//import TopImage from '../../assets/rotating-picture-slice-tmp.jpg'
import TopImage from '../../assets/profile-top-02.jpg'
import MiddleImage from '../../assets/profile-middle-02.jpg'
import BottomImage from '../../assets/profile-bottom-02.jpg'

class RotatingPicture extends Component {

	constructor(props) {
		super(props)

		this.ref = React.createRef();

		this.state = {
			width: 375,
		}
	}

	componentDidMount() {
		const resizeObserver = new ResizeObserver((entries) => {
			const width = entries[0].target.scrollWidth
			this.setState({
				width: width,
			})
		});

		resizeObserver.observe(this.ref.current);
	}

	render() {
		return (
			<div className="col s12 l4 push-l8">
				<div className="rotating-picture" ref={this.ref}>
					<RotatingPictureSlice src={TopImage} numberOfFrames={5} frameWidth={375} frameHeight={124} containerWidth={this.state.width} />
					<RotatingPictureSlice src={MiddleImage} numberOfFrames={5} frameWidth={375} frameHeight={124} containerWidth={this.state.width} />
					<RotatingPictureSlice src={BottomImage} numberOfFrames={5} frameWidth={375} frameHeight={124} containerWidth={this.state.width} />
				</div>
				<span className="right picture-blurb">Adam Passey in all his forms.</span>
			</div>
		)
	}
}

export default RotatingPicture