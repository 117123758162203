import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Cursor } from '../index'

import './headline.css'

class Headline extends Component {

	constructor(props) {
		super(props)

		const initialString = this.chooseRandomDoes()

		this.state = {
			doesString: initialString,
			chosenDoesString: initialString,
			deleting: true,
		}
	}

	componentDidMount = () => {
		this.startTick()
	}

	chooseRandomDoes = () => {
		const index = Math.floor(Math.random() * Math.floor(this.props.does.length))
		return this.props.does[index]
	}

	prepareNextDoes = () => {
		this.setState({
			doesString: '',
			chosenDoesString: this.chooseRandomDoes(),
		})
	}

	deleteCharacterInDoes = () => {
		this.setState({
			doesString: this.state.doesString.substring(0, this.state.doesString.length - 1)
		})
	}

	addCharacterToDoes = () => {
		const char = this.state.chosenDoesString.substring(this.state.doesString.length, this.state.doesString.length + 1)
		this.setState({
			doesString: this.state.doesString + char,
		})
	}

	startTick = () => {
		this.setState({
			interval: setInterval(this.tick, this.props.typeSpeed)
		})
	}

	tick = () => {
		if (this.state.deleting) {
			if (this.state.doesString.length > 0) {
				this.deleteCharacterInDoes()
			} else {
				this.prepareNextDoes()
				this.setState({ deleting: false })
			}
		} else {
			if (this.state.doesString.length < this.state.chosenDoesString.length) {
				this.addCharacterToDoes()
			} else {
				this.setState({ deleting: true })
				clearInterval(this.state.interval)
				setTimeout(this.startTick, this.props.typeCompletionDelay)
			}
		}
	}

	render() {
		return (
			<div className="headline">
				<span>
					{this.props.children}<br />
					{this.state.doesString}
					<Cursor />
				</span>
			</div>
		)
	}
}

Headline.propTypes = {
	does: PropTypes.array.isRequired,
	typeSpeed: PropTypes.number,
	typeCompletionDelay: PropTypes.number,
}

Headline.defaultProps = {
	typeSpeed: 1000 / 20,
	typeCompletionDelay: 2000,
}

export default Headline