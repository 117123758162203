import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './project.css'

class Project extends Component {

	constructor(props) {
		super(props)

		this.modal = React.createRef()

		this.state = {
			modalVisible: false,
		}
	}

	id = () => {
		return this.props.title.toLowerCase().replace(/\W+/g,"")
	}

	showModal = () => {
		this.setState({
			modalVisible: true,
		})
	}

	hideModal = () => {
		this.setState({
			modalVisible: false,
		})
	}

	render() {
		const {
			image,
			title,
			description,
			linkText,
			linkHref,
			openLinkInModal,
			embedCode,
			materializeSize,
		} = this.props

		const {
			modalVisible,
		} = this.state

		const id = this.id()

		return ( 
			<div className={`col ${materializeSize}`}>
				{ openLinkInModal && modalVisible &&
					<div id={id} className={`custom-modal ${this.state.modalVisible ? 'visible' : 'hidden'}`}>
						<div className="modal-content">
							<h5>{title}</h5>
							<i className="small material-icons custom-modal-close-button" onClick={this.hideModal}>close</i>
							<div className="video-container" dangerouslySetInnerHTML={{
								__html: embedCode
							}}>
							</div>
						</div>
					</div>
				}
				<div className="project card large">
					<div className="card-image">
						<img src={image} alt={title} />
						<span className="card-title">{title}</span>
					</div>
					<div className="card-content">
						<p>{description}</p>
					</div>
					<div className="card-action">
						{ openLinkInModal &&
							<button data-target={id} title={linkText} className="btn" onClick={this.showModal}>{linkText}</button>
						}
						{ !openLinkInModal && 
							<a href={linkHref} title={linkText} target="_blank" rel="noreferrer" className="btn">{linkText}</a>
						}
					</div>
				</div>
			</div>
		)
	}
}

Project.propTypes = {
	image: PropTypes.any.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	linkText: PropTypes.string,
	linkHref: PropTypes.string,
	openLinkInModal: PropTypes.bool,
	embedCode: PropTypes.string,
	materializeSize: PropTypes.string,
}

Project.defaultProps = {
	linkText: "View on Github",
	title: "",
	description: "",
	openLinkInModal: false,
	materializeSize: "s12 m4 l3 xl2"
}

export default Project