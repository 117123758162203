import React, { Component } from 'react'
import { 
    FastCompany,
    Footer,
    Headline,
    Logo,
    OpenSource,
    PixelArt,
    Projects,
    WorkHistory,
} from '../../modules'
import './index.css';

import Resume from '../../assets/Adam Passey Resume & Cover Letter.pdf'

class IndexPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            whatDo: [
                "I build video games.",
                "I create pixel art.",
                "I build API's.",
                "I scale services.",
                "I design topology.",
                "I lead engineering teams.",
                "I like whiskey.",
                "I miss my dog.",
                "I play video games.",
                "I vote.",
                "I design systems.",
                "I craft cocktails.",
                "I have a sweet mustache.",
                "I'm a dad!",
                "I write code.",
            ]
        }
    }

    render() {
        return (
            <div className="app">
                <header className="app-header">
                    <Logo size="medium"/>
                    <p className="right resume-link">
                        <a href={Resume} title="Download Adam Passey's Resume">
							<i className="material-icons small">description</i>
							Download my resume
						</a>
                    </p>
                    <div className="container--headline">
                        <div className="row col s12">
                            <PixelArt />
                            <Headline does={this.state.whatDo}>
                                <span className="name">I'm Adam Passey.</span>
                            </Headline>
                        </div>
                    </div>
                </header>
                <main>
                    <WorkHistory />
                    <Projects />
                    <FastCompany />
                    <OpenSource />
                </main>
                <Footer />
            </div>
        )
    }
}

export default IndexPage
