import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Project from '../project'
import CollectSource from '../../assets/collect-500.jpg'
import RouteSource from '../../assets/route-500.jpg'
import TiledSource from '../../assets/tiled-500.gif'
import i18nSource from '../../assets/i18n-500.jpg'

import './open-source.css'

class OpenSource extends Component {
	render() {
		return (
			<div className="container--open-source">
				<div className="container--open-source-sub-container">
					<div className="container">
						<h4 className="open-source-sub-head--blocky">{this.props.headline}</h4>
						<div className="row">
							<Project 
								image={CollectSource} 
								title="Collect"
								description="Drag-and-drop grid-based inventory for Unity 4.6+"
								linkText="View on Github"
								linkHref="https://github.com/adampassey/collect"
								materializeSize="s12 m6 xl3"
							/>
							<Project 
								image={RouteSource} 
								title="Route"
								description="Weighted graph heuristic pathfinding package for Unity."
								linkText="View on Github"
								linkHref="https://github.com/adampassey/route"
								materializeSize="s12 m6 xl3"
							/>
							<Project 
								image={TiledSource} 
								title="Unity Tiled Importer"
								description="Unity package for importing 2D and isometric maps from the Tiled map editor."
								linkText="View on Github"
								linkHref="https://github.com/adampassey/unity-tiled-importer"
								materializeSize="s12 m6 xl3"
							/>
							<Project 
								image={i18nSource} 
								title="Unity i18n"
								description="i18n Package for Unity."
								linkText="View on Github"
								linkHref="https://github.com/adampassey/unity-i18n"
								materializeSize="s12 m6 xl3"
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

OpenSource.propTypes = {
	headline: PropTypes.string,
}

OpenSource.defaultProps = {
	headline: "Open Source",
}

export default OpenSource