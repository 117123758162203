import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './cursor.css'

class Cursor extends Component {

	constructor(props) {
		super(props)

		this.state = {
			cursor: true,
		}

		setInterval(this.tick, this.props.interval)
	}

	tick = () => {
		const cursor = this.state.cursor ? false : true
		this.setState({
			cursor: cursor,
		})
	}

	render() {
		return (
			<span className={this.state.cursor ? 'cursor visible' : 'cursor hidden'}>|</span>
		)
	}
}

Cursor.propTypes = {
	interval: PropTypes.number,
}

Cursor.defaultProps = {
	interval: 500,
}

export default Cursor