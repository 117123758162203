import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './rotating-picture-slice.css'

class RotatingPictureSlice extends Component {
	constructor(props) {
		super(props)

		this.state = {
			index: 0,
			left: 0,
		}
	}	

	componentDidMount = () => {
		setInterval(this.tick, this.props.changeFrequency)
	}

	tick = () => {
		const newIndex = Math.floor(Math.random() * Math.floor(this.props.numberOfFrames))

		this.setState({
			index: newIndex,
			left: newIndex * this.props.containerWidth,
		})
	}

	componentDidUpdate = (prevProps) => {
		if (prevProps.containerWidth === this.props.containerWidth) {
			return;
		}

		this.setState({
			left: this.state.index * this.props.containerWidth,
		})
	}


	render() {
		const height = (this.props.containerWidth / this.props.frameWidth) * this.props.frameHeight

		const imageStyle = {
			marginLeft: - this.state.left,
			width: this.props.containerWidth * this.props.numberOfFrames,
			height: height,
		}

		const containerStyle = {
			height: height,
		}

		return (
			<div className="rotating-picture-slice-window">
				<div className="slice-container" style={containerStyle}>
					<img
						className="rotating-picture-slice"
						style={imageStyle}
						src={this.props.src} 
						alt="" />
				</div>
			</div>
		)
	}
}

RotatingPictureSlice.propTypes = {
	src: PropTypes.string.isRequired,
	numberOfFrames: PropTypes.number.isRequired,
	containerWidth: PropTypes.number.isRequired,
	frameWidth: PropTypes.number,
	frameHeight: PropTypes.number,
	changeFrequency: PropTypes.number,
}

RotatingPictureSlice.defaultProps = {
	frameWidth: 375,
	frameHeight: 124,
	changeFrequency: 3000,
}

export default RotatingPictureSlice